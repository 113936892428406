import ReactMarkdown from 'react-markdown';
import rehypeExternalLinks from 'rehype-external-links';
import rehypeRaw from 'rehype-raw';
import { StyledMarkdown } from './MarkdownComponent.styled';

type MarkdownComponentProps = {
  content: string;
};

const ALLOWED_ELEMENTS = [
  'p',
  'strong',
  'ul',
  'ol',
  'li',
  'em',
  'a',
  'br',
  'img',
  'u',
  'blockquote'
];

export const MarkdownComponent: React.FC<MarkdownComponentProps> = ({
  content
}) => {
  return (
    <StyledMarkdown>
      <ReactMarkdown
        allowedElements={ALLOWED_ELEMENTS}
        rehypePlugins={[[rehypeExternalLinks, { target: '_blank' }], rehypeRaw]}
      >
        {content}
      </ReactMarkdown>
    </StyledMarkdown>
  );
};

export default MarkdownComponent;
